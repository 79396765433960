<template>
  <div class="categories">
    <div class="categories__actions">
      <h3>
        Issues Categories
      </h3>
      <call-button
        size="mini"
        :icon="dataAddIcon"
        @click="$emit('add-category')">
        Add Category
      </call-button>
    </div>
    <call-ui-categories-item
      v-for="item in categories"
      :key="item.id"
      :categories-item="item"
      @edit-categories="(e) => $emit('update-category', e)" />
  </div>
</template>

<script>
import CallButton from '@/components/common/CallButton';
import CallUiCategoriesItem from '@/components/common/ui_categories/CallUiCategoriesItem';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CallUiCategories',
  components: { CallUiCategoriesItem, CallButton },
  props: {
    categories: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      dataAddIcon: faPlus
    };
  }
};
</script>

<style scoped lang="sass">
.categories
  padding: 16px 0
  width: 280px
  &__actions
    display: flex
    padding: 0 16px
    align-items: center
    justify-content: space-between
    margin-bottom: 20px
    h3
      font-size: 14px
      font-family: 'cera-bold'
      margin-bottom: 0
    .call-button
      padding: 0
      background-color: transparent
      color:  #20a052
      line-height: 1
      &:focus
        box-shadow: none
</style>
