<template>
  <div class="home">
    <div class="filter-wrapper">
      <div class="link-wrapper">
        <router-link
          :to="{ name: 'CallsRecords'}">
          Records
        </router-link>
        <router-link :to="{ name: 'CallsLogs'}">
          Logs
        </router-link>
        <router-link
          v-if="computedIsAdmin"
          :to="{ name: 'CallsIssues'}">
          Issues
        </router-link>
        <router-link
          :to="{ name: 'CallsReport'}">
          Issues Report
        </router-link>
        <v-select
          v-model="dataSelectIssuesCategoriesValue"
          :options="dataListIssuesCategories"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="All Categories"
          :class="['call-select call-select-filter', dataSelectIssuesCategoriesValue ? 'call-select--value' : null]"
          @input="$_ajax({ reset: true })">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <v-select
          v-model="dataSelectTeamsValue"
          :options="dataSelectOptionsTeam"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="All Teams"
          :class="['call-select call-select-filter', dataSelectTeamsValue ? 'call-select--value' : null]"
          @input="$_ajax({ reset: true })">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
      </div>
      <div>
        <popover
          v-model="dataShowPopover"
          overlay
          trigger="click">
          <call-ui-categories
            :categories="dataListIssuesCategories"
            @update-category="(e) => {dataPropsItemIssuesCategories = e; dataShowPopover = false; dataShowIssuesUpdateCategories = true}"
            @add-category="() => {dataShowPopover = false; dataShowIssuesCategories = true}" />
          <template #reference>
            <call-button
              :icon="dataIconList"
              style="position: relative">
              Manage Categories
            </call-button>
          </template>
        </popover>
        <call-pagination
          :page-value="50"
          :obj-pagination="dataObjPagination"
          @on-pagination="$_ajax" />
        <call-button
          v-if="computedIsAdmin"
          :icon="dataAddIcon"
          type="success"
          @click="clickShowAddIssues()">
          Add Issues
        </call-button>
      </div>
    </div>
    <div class="table-wrapper">
      <b-table
        striped
        hover
        show-empty
        no-local-sorting
        :fields="dataMixinCallIssuesFields"
        :items="dataCallIssuesList"
        @sort-changed="onSortTabel"
        @row-hovered="onHover"
        @row-clicked="(value) => onActions(value, 'view')"
        @row-unhovered="onUnHover">
        <template #cell(created_at)="data">
          {{ data.item.created_at | dateFilter }}
        </template>
        <template #cell(description)="data">
          <call-tooltip
            placement="bottom"
            :content="data.item.description">
            <span>{{ data.item.description }}</span>
          </call-tooltip>
        </template>
        <template #cell(teams)="data">
          <call-tooltip
            placement="bottom"
            :content="data.item.teams.map(item => item.name).join('\n')">
            <span>{{ data.item.teams.length }} Teams</span>
          </call-tooltip>
        </template>
        <template #cell(actions)="data">
          <call-table-issues-actions
            v-show="data.item.actions"
            @on-action="(value) => onActions(data.item, value)" />
        </template>
      </b-table>
    </div>
    <call-modal-add-issues-categories
      v-model="dataShowIssuesCategories"
      @on-update="$_ajaxIssuesCategories" />
    <call-modal-add-issues
      v-model="dataShowIssues"
      @on-update="$_ajax" />
    <call-modal-update-issues
      v-model="dataShowUpdateIssues"
      :obj-issues="dataPropsUpdateIssues"
      @on-update="$_ajax" />
    <call-modal-update-issues-categories
      v-model="dataShowIssuesUpdateCategories"
      :obj-category="dataPropsItemIssuesCategories"
      @on-update="$_ajaxIssuesCategories" />
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';
import { ISSUES_GET, ISSUES_GET_CATEGORIES, TEAMS_GET_ALL } from '../../../store/types/actions.types';
import helper from '../../../service/helper';
import CallPagination from '@/components/desktop/CallPagination';
import { faCheck, faClock, faComments, faList, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { mapGetters } from 'vuex';
import CallButton from '../../../components/common/CallButton';
import Popover from 'vant/lib/popover';
import 'vant/lib/popover/style';
import CallUiCategories from '@/components/common/ui_categories/CallUiCategories';
import selectAllTeams from '@/mixins/select/selectAllTeams';
import callIssuesTable from '@/mixins/table/callIssuesTable';
import CallModalAddIssuesCategories from '@/components/common/modals/CallAddIssuesCategories';
import CallModalAddIssues from '@/components/common/modals/CallAddIssues';
import CallModalUpdateIssuesCategories from '@/components/common/modals/CallUpdateIssuesCategories';
import CallTableIssuesActions from '@/components/desktop/CallTableIssuesActions';
import CallModalUpdateIssues from '@/components/common/modals/CallUpdateIssues';
import selectIssuesCategories from '@/mixins/select/selectIssuesCategories';

export default {
  name: 'PageSms',
  components: {
    CallModalUpdateIssues,
    CallTableIssuesActions,
    CallModalUpdateIssuesCategories,
    CallModalAddIssues,
    CallModalAddIssuesCategories,
    CallUiCategories,
    CallPagination,
    BTable,
    Popover,
    CallButton
  },
  mixins: [
    callIssuesTable,
    selectAllTeams,
    selectIssuesCategories
  ],
  data () {
    const issuesCategories = {
      dataShowPopover: false,
      dataShowIssuesCategories: false,
      dataShowIssuesUpdateCategories: false,
      dataPropsItemIssuesCategories: {},
      dataListIssuesCategories: []
    };
    return {
      dataIconCheck: faCheck,
      dataIconError: faTimes,
      dataIconClock: faClock,
      dataIconComment: faComments,
      dataIconList: faList,
      dataAddIcon: faPlus,
      dataObjPagination: {},
      dataCallIssuesList: [],
      dataDesc: null,
      dataShowIssues: false,
      dataShowUpdateIssues: false,
      dataPropsUpdateIssues: {},
      ...issuesCategories
    };
  },
  computed: {
    ...mapGetters({
      computedIsOperator: 'auth/isOperator',
      computedIsModerator: 'auth/isModerator',
      computedCurrentUser: 'auth/currentUser',
      computedIsAdmin: 'auth/isAdmin'
    })
  },
  async created () {
    this.$_ajax = ((options = {}) => {
      let _page = 0;
      let _limit = 50;

      const { currentRoute: { name: pageName } = {} } = this.$router;

      const _filterKey = `_filter${pageName}`;

      if (!helper.isEmpty(this.$route.query, _filterKey)) {
        try {
          options = helper.clearObject(JSON.parse(this.$route.query[_filterKey]));

          const _arrFilter = [
            'desc',
            'orderBy',
            'limit',
            'page',
            'period',
            'team_ids',
            'operator_ids',
            'categoryId',
            'limit',
            'page',
            'search',
            'statuses'
          ];

          for (const _key of _arrFilter) {
            if (!helper.isEmpty(options, _key)) {
              switch (_key) {
                case 'desc':
                  this.dataDesc = options[_key];
                  break;
                case 'limit':
                  _limit = options[_key];
                  break;
                case 'page':
                  _page = options[_key];
                  break;
                case 'teamId':
                  this.dataSelectTeamsValue = options[_key];
                  break;
                case 'categoryId':
                  this.dataSelectIssuesCategoriesValue = options[_key];
                  break;
              }
            }
          }
        } catch (e) {
          console.log('Error query params');
        }
      }

      return async ({ desc = this.dataDesc, teamId = this.dataSelectTeamsValue, categoryId = this.dataSelectIssuesCategoriesValue, limit = null, page = null, reset = false } = options) => {
        if (!helper.isEmpty(page) && page !== _page) {
          _page = page;
        }

        if (!helper.isEmpty(limit) && limit !== _limit) {
          _limit = limit;
        }

        if (reset) {
          _page = 0;
        }

        const [{ payload: { list = [] }, misc: { paging = {} } = {} }] = await Promise.all([
          this.$store.dispatch(`issues/${ISSUES_GET}`, {
            params: {
              desc,
              limit: _limit,
              page: _page,
              team_ids: [teamId?.id],
              category_ids: [categoryId?.id]
            }
          })
        ]);

        this.dataObjPagination = paging;
        this.dataCallIssuesList = list.map((item) => {
          item.actions = false;
          return item;
        });

        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            [_filterKey]: JSON.stringify(helper.clearObject({
              desc,
              limit: _limit,
              page: _page,
              teamId,
              categoryId
            }))
          }
        }).catch(() => {});

        options = {};
      };
    })();

    const [{ payload: { list: listTeam = [] } }] = await Promise.all([
      this.$store.dispatch(`team/${TEAMS_GET_ALL}`, {
        params: {
          short: true
        }
      }),
      this.$_ajax(),
      this.$_ajaxIssuesCategories()
    ]);

    this.dataSelectOptionsTeam = listTeam;
  },
  methods: {
    async $_ajaxIssuesCategories () {
      try {
        this.dataListIssuesCategories = await this.$store.dispatch(`issues/${ISSUES_GET_CATEGORIES}`, {});
      } catch (e) {
        console.log(e);
      }
    },
    clickShowAddIssues () {
      this.dataShowIssues = true;
    },
    onSortTabel ({ sortDesc }) {
      this.dataDesc = sortDesc;
      this.$_ajax();
    },
    async  onActions (item, action) {
      switch (action) {
        case 'update':
          this.dataPropsUpdateIssues = item;
          this.dataShowUpdateIssues = true;
          break;
      }
    }
  }
};
</script>
<style scoped lang="sass">
.van-popover__wrapper
  margin-right: 8px
  .call-button
    font-family: "cera-regular"
</style>
