<template>
  <div
    class="categories-item"
    @click="$emit('edit-categories', categoriesItem)">
    {{ categoriesItem.name }} ({{ categoriesItem.issues_count }})
    <font-awesome-icon :icon="dataIconPencilAlt" />
  </div>
</template>

<script>
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'CallUiCategoriesItem',
  props: {
    categoriesItem: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dataIconPencilAlt: faPencilAlt
    };
  }
};
</script>

<style scoped lang="sass">
.categories-item
  padding: 11px 16px
  display: flex
  justify-content: space-between
  cursor: pointer
  transition: .3s ease-out
  .svg-inline--fa
    color: #e96c2c
    opacity: 0
    transition: .3s ease-out
  &:hover
    background-color: #f9f9f9
    .svg-inline--fa
      opacity: 1
</style>
