export default {
  data () {
    return {
      dataMixinCallIssuesFields: [
        {
          label: 'ID',
          key: 'id',
          sortable: false,
          tdClass: 'call-td call-td--grey',
          thClass: 'call-th'
        },
        {
          label: 'Category',
          key: 'category_name',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Issues',
          key: 'name',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Description',
          key: 'description',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Available For',
          key: 'teams',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: 'Created at',
          key: 'created_at',
          sortable: false,
          tdClass: 'call-td',
          thClass: 'call-th'
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'call-tabel-action',
          thClass: 'call-tabel-action'
        }
      ]
    };
  },
  methods: {
    onHover (item) {
      item.actions = true;
    },
    onUnHover (item) {
      item.actions = false;
    }
  }
};
